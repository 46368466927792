<style scoped>
.contentItem {
  text-align: center;
  background: white;
  width: 100%;
  margin: 10px auto;
  padding: 10px 2px;
  box-shadow: 0px 3px 3px #1e9bcc0d;
}
.contentItem .contentTitle {
  text-align: left;
  width: 100%;
  font-size: 1rem;
}
.contentItem .contentConMin {
  width: 100%;
  color: #a19393;
  text-align: left;
  padding-top: 4px;
  font-size: 0.8rem;
  display: flex;
}
/* .contentItem .contentGrade {
  width: 100%;
  color: #a19393;
  text-align: left;
  padding-left: 10px;
  padding-top: 8px;
  font-size: 12px;
} */

.warning {
  background-color: #ff5722 !important;
  background-image: linear-gradient(
    to top,
    rgb(255, 152, 0) 0%,
    rgb(255, 87, 34) 100%
  ) !important;
}
.toUrlStyleTo {
  padding: 3px 10px;
  background: #5b7498;
  border-radius: 3px;
  color: white;
  font-size: 10px;
}
</style>
<template>
  <div>
    <div style="text-align: left; margin: 10px">
      <div v-for="(item, index) in listCon" :key="index" class="contentItem">
        <div class="contentTitle">{{ item.title }}</div>
        <div
          style="display: flex; padding: 8px 0; justify-content: space-between"
        >
          <div style="flex: 1; margin-right: 8px" class="contentConMin">
            <div>{{ item.name }}</div>
            <div style="margin-left: 10px" v-if="item.clickNum || item.likeNum">
              {{ item.clickNumTotal }}阅读 &nbsp; {{ item.likeNum }}点赞
            </div>
          </div>
          <div style="">
            <div class="toUrlStyleTo" @click="chooseItem(item)">选择</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rnContent",
  data() {
    return {};
  },
  props: {
    // 内容数据
    listCon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 视频展示结构 左右/上下
    layoutStyle: {
      type: String,
      default: "toLeft",
    },
  },
  methods: {
    chooseItem(data) {
      this.$emit("chooseItem", data);
    },
  },
};
</script>
