<template>
  <div id="descComAndSort" @click.stop>
    <div
      style="
        justify-content: space-between;
        display: flex;
        padding: 10px 0;
        font-size: 14px;
      "
    >
      <div
        @click="$emit('chooseContent')"
        style="
          flex: 1;
          text-align: left;
          color: #aaa;
          align-items: center;
          display: flex;
        "
      >
        <svg
          style="display: block"
          t="1648189599515"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="11006"
          width="16"
          height="16"
        >
          <path
            d="M757.8112 950.3232H271.5648c-107.264 0-194.2016-86.9376-194.2016-194.2016V269.824c0-107.264 86.9376-194.2016 194.2016-194.2016h486.2464c107.264 0 194.2016 86.9376 194.2016 194.2016v486.2464c0 107.264-86.9376 194.2528-194.2016 194.2528z"
            fill="#F78748"
            p-id="11007"
          ></path>
          <path
            d="M790.2208 514.1504c-14.1312 0-25.6 11.4688-25.6 25.6v172.5952c0 46.1824-37.5808 83.7632-83.7632 83.7632h-322.048c-46.1824 0-83.7632-37.5808-83.7632-83.7632V382.3616c0-46.1824 37.5808-83.7632 83.7632-83.7632h7.8848c10.24 45.312 50.7392 79.2576 99.072 79.2576h108.0832c48.3328 0 88.832-33.9456 99.072-79.2576h7.8848c46.1824 0 83.7632 37.5808 83.7632 83.7632 0 14.1312 11.4688 25.6 25.6 25.6s25.6-11.4688 25.6-25.6c0-74.3936-60.5184-134.9632-134.9632-134.9632h-9.5744c-12.4416-42.0352-51.4048-72.8064-97.4336-72.8064h-108.032c-46.0288 0-84.9408 30.7712-97.4336 72.8064h-9.5744c-74.3936 0-134.9632 60.5184-134.9632 134.9632v330.0352c0 74.3936 60.5184 134.9632 134.9632 134.9632h322.048c74.3936 0 134.9632-60.5184 134.9632-134.9632v-172.5952a25.5488 25.5488 0 0 0-25.5488-25.6512zM465.7664 225.792h108.0832c27.8016 0 50.432 22.6304 50.432 50.432s-22.6304 50.432-50.432 50.432H465.7664c-27.8016 0-50.432-22.6304-50.432-50.432s22.6304-50.432 50.432-50.432z"
            fill="#F7F8F8"
            p-id="11008"
          ></path>
          <path
            d="M615.2704 468.5824H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6zM615.2704 613.2224H424.3968c-14.1312 0-25.6 11.4688-25.6 25.6s11.4688 25.6 25.6 25.6h190.8736c14.1312 0 25.6-11.4688 25.6-25.6s-11.4688-25.6-25.6-25.6z"
            fill="#F7F8F8"
            p-id="11009"
          ></path>
        </svg>
        <div style="font-weight: bold">选择文章</div>
      </div>

      <div style="flex: 1; text-align: left" v-if="isFilter">
        <div
          class="dropdown"
          style="
            font-weight: bold;
            position: absolute;
            display: flex;
            right: 20px;
          "
          @click="sort"
        >
          <div style="color: #515a6e">排序</div>
          <svg
            t="1657850692458"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8637"
            width="20"
            height="20"
          >
            <path
              d="M437 885.4c-9.4 0-18.6-3.7-25.5-10.5L226.7 690.1c-14.1-14.1-14.1-36.9 0-50.9 14.1-14.1 36.9-14.1 50.9 0L401 762.5V178c0-19.9 16.1-36 36-36s36 16.1 36 36v671.4c0 14.6-8.8 27.7-22.2 33.3-4.5 1.8-9.2 2.7-13.8 2.7zM586 560c-19.9 0-36-16.1-36-36V178c0-14.6 8.8-27.7 22.2-33.3 13.5-5.6 29-2.5 39.2 7.9l169 169.5c14 14.1 14 36.9-0.1 50.9s-36.9 14-50.9-0.1L622 265.1V524c0 19.9-16.1 36-36 36zM802 761H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 653H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36zM802 869H676c-19.9 0-36-16.1-36-36s16.1-36 36-36h126c19.9 0 36 16.1 36 36s-16.1 36-36 36z"
              p-id="8638"
            ></path>
            <path
              d="M586.9 617m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8639"
            ></path>
            <path
              d="M586.9 725m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8640"
            ></path>
            <path
              d="M585.9 833m-36 0a36 36 0 1 0 72 0 36 36 0 1 0-72 0Z"
              p-id="8641"
            ></path>
          </svg>
        </div>
        <div
          style="
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div id="sortContainer" class="sortContainer">
            <div
              style="
                height: 40px;
                box-shadow: 0 1px 0 #409ad5 inset, 0 -1px 0 #20638f inset;
              "
              :class="showFilterClass"
              @click="sortData(item)"
              v-for="(item, key) in sortList"
              :key="key"
            >
              {{ item.name }}
              <svg
                t="1657855187286"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="9893"
                width="12"
                height="12"
              >
                <path
                  fill="#fff"
                  d="M279.15323 958.059228l217.110799-363.160177-141.539436 0L354.724593 63.957829l-151.123938 0 0 530.943021L62.057421 594.900849 279.15323 958.059228 279.15323 958.059228zM570.078783 64.464885l386.443791 0 0 108.976114L570.078583 173.440999 570.078783 64.464885 570.078783 64.464885zM570.078783 369.594007 878.364965 369.594007l0-108.974515L570.078783 260.619492 570.078783 369.594007zM570.078783 565.747016l230.128573 0 0-108.976114L570.078783 456.770901 570.078783 565.747016 570.078783 565.747016zM570.078783 761.904621l151.972163 0L722.050945 652.930305l-151.972163 0L570.078783 761.904621zM570.078783 958.059228l73.813355 0 0-108.974315-73.813355 0L570.078783 958.059228z"
                  p-id="9894"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";

export default {
  name: "descCom",
  components: {},
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    isFilter: {
      type: Boolean,
      default: false,
    },

    sortList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 选择日期
      _startDate: "",
      _endDate: "",

      showFilterClass: "hide",
    };
  },
  methods: {
    sort() {
      // 下拉展开
      if (this.showFilterClass == "show") {
        this.showFilterClass = "hide";
      } else {
        this.showFilterClass = "show";
      }
    },
    sortData(data) {
      this.showFilterClass = "hide";
      this.$emit("sortData", data);
    },
    removeLister() {
      this.showFilterClass = "hide";
    },
  },
  mounted() {
    document.body.addEventListener("click", this.removeLister);
  },
  destroyed() {
    console.log("移除监听");
    document.body.removeEventListener("click", this.removeLister);
  },
  async created() {
    this._startDate =
      this.startDate || moment().subtract(7, "day").format("YYYY-MM-DD");

    this._endDate = this.endDate || moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
</style>
