<style scoped>
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  overflow: scroll;
  background: white;
}
.content {
  border-bottom: 1px solid var(--borderColor);
  position: relative;
  padding: 14px 10px 8px;
}
.formItem {
  margin-top: 3px;
  padding: 6px 10px;
  margin: 5px 0;
  box-shadow: 0 1px 6px rgb(0 0 0 / 8%);
  display: flex;
  justify-content: space-between;
  min-height: 41px;
  /* line-height: 41px; */
}
.formItem .formItemTitle {
  font-weight: bold;
  color: #2d4761;
}
.formItem .detail {
  width: 80%;
}
.overFlowellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.searchContentList {
  width: 100%;
  height: 90%;

  overflow: scroll;
  background: white;
  position: fixed;
  top: 44px;
  left: 0;
}
</style>
<template>
  <div>
    <div class="search-container">
      <search-top
        :needEmit="true"
        @searchBack="searchBack"
        @search="searchCon"
        title="检索"
      ></search-top>

      <div v-if="listCon" class="searchContentList">
        <rn-content-min
          @chooseItem="chooseItem"
          :listCon="listCon"
        ></rn-content-min>
        <load-more
          @refresh="onRefresh"
          :load-status="isLoadMore"
          :isShow="loadMoreShow"
        ></load-more>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *  默认查询精选文章
 */
import _ from "lodash";

import { wxConPost, getUserPm } from "../api/httpApi";
import searchTop from "./searchTop.vue";
import loadMore from "./loadMoreData.vue";
import rnContentMin from "./rnContentMin.vue";

export default {
  name: "searchContentList",
  display: "检索文章",
  data() {
    return {
      limit: 10,
      searchContent: "", // 输入的搜索内容
      initContent: true, // 默认显示感兴趣的内容
      close: false, // 清楚按钮
      loadMoreShow: false,
      isLoadMore: true,
      listCon: [],
      curContent: {},
      postBody: {},
      postUrl: "/services/gttt-content-mgt-get/wxContent/jingxuanList",
      // || "/services/gttt-wxstats-mgt/search/getSearch"
    };
  },
  props: {
    showLoad: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  computed: {},
  components: { searchTop, loadMore, rnContentMin },
  methods: {
    chooseItem(data) {
      this.curContent = data;
      this.searchContent = "";
      // this.postUrl = "/services/gttt-content-mgt-get/wxContent/jingxuanList";
      // this.postBody = {
      //   title: this.searchContent,
      //   limit: this.limit,
      //   sort: { updated: -1 },
      //   skip: 0,
      // }
      this.$emit("chooseItem", data);
    },

    searchBack() {
      this.$emit("searchBack");
    },

    // 检索文章
    searchCon(title) {
      this.searchContent = title;
      this.listCon = [];
      this.postBody = {
        title: this.searchContent,
        limit: this.limit,
        sort: { updated: -1 },
        skip: this.listCon.length,
      };
      this.postUrl = "/services/gttt-wxstats-mgt/search/getSearch";
      this.getList(title);
    },

    async getList() {
      try {
        const data = await wxConPost(this.postUrl, this.postBody);
        this.listCon = [];
        if (data.value.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
          return;
        }
        for (let i in data.value) {
          const item = data.value[i];
          let columntype = "text";
          if (item.video) {
            columntype = "video";
          } else if (item.img.length > 0) {
            columntype = "image";
          }
          let listData = item;
          Object.assign(listData, {
            type: columntype,
            time: item.topTime
              ? new Date(item.topTime).getTime()
              : new Date(item.updated).getTime(),
            keywords: item.keywords ? item.keywords.join(" ") : "",
            wxTVideo: item.type ? true : false,
            id: item._cid,
            clickNumTotal:
              item.clickNum >= 10000
                ? `${Math.ceil(parseFloat(item.clickNum / 1000, 10)) / 10}万`
                : item.clickNum,
          });
          if (item.column["1"]) {
            listData.name = item.column["1"];
          } else {
            listData.name = item.column["0"];
          }
          this.listCon.push(listData);
        }
        // 显示加载
        this.isLoadMore = true;
        this.loadMoreShow = true;
      } catch (error) {
        console.log("getNewsTypes", error);
      }
    },

    // loadMore
    async onRefresh() {
      console.log("onRefresh----");
      try {
        let _query = {
          ...this.postBody,
        };
        _query["skip"] = this.listCon.length; //分页skip会一直 0 重新赋值

        const data = await wxConPost(this.postUrl, _query);
        if (data.value.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
          return;
        }
        for (let i in data.value) {
          const item = data.value[i];
          let columntype = "text";
          if (item.video) {
            columntype = "video";
          } else if (item.img.length > 0) {
            columntype = "image";
          }
          let listData = item;
          Object.assign(listData, {
            type: columntype,
            time: item.topTime
              ? new Date(item.topTime).getTime()
              : new Date(item.updated).getTime(),
            keywords: item.keywords ? item.keywords.join(" ") : "",
            wxTVideo: item.type ? true : false,
            id: item._cid,
            clickNumTotal:
              item.clickNum >= 10000
                ? `${Math.ceil(parseFloat(item.clickNum / 1000, 10)) / 10}万`
                : item.clickNum,
          });
          if (item.column["1"]) {
            listData.name = item.column["1"];
          } else {
            listData.name = item.column["0"];
          }
          this.listCon.push(listData);
        }
        // 显示加载
        this.isLoadMore = true;
        this.loadMoreShow = true;
      } catch (error) {
        console.log("onRefresh---", error);
      }
    },
  },
  async created() {
    const userPm = getUserPm();
    if (userPm) {
      this.postBody = {
        limit: this.limit,
        sort: { updated: -1 },
        skip: this.listCon.length,
      };
      this.getList();
    }
  },
  beforeDestroy() {},
};
</script>
