<style scoped>
.back {
  padding: 3px 10px;
  height: 44px;
  background: white;
  font-weight: bold;
  line-height: 44px;
  display: flex;
  position: relative;
  text-align: center;
  color: #5a5f69;

  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
}
.mm-searchTitle {
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* .mm-return {
  width: 10%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
} */
.mm-search {
  /* border: 1px solid #133c72;
  box-shadow: 0px 0px 20px 1px #133c72 inset; */
  box-shadow: 0px 0px 1px 1px #b9b8b8 inset;
  height: 30px;
  border-radius: 5px;
  line-height: 30px;
  position: relative;
  padding: 0 10px;
  margin-left: 10%;
  width: 80%;
  display: flex;
  justify-content: space-around;
}

.mm-search input {
  flex: 1;
  font-weight: bold;
  outline: none;
  border: none;
  background: transparent;
}
.mm-search .mm-close {
  line-height: 35px;
}

.mm-cancle {
  width: 15%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 1px #b9b8b8 inset;
}

input:focus {
  outline: none;
}
.selectContent {
  width: 33%;
}
.mm-detailCon {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #fff;
}
.searchIcon {
  color: #333;
  margin: 0 2px;
  font-size: 16px;
}
</style>
<template>
  <div class="back">
    <div class="mm-searchTitle">
      <div
        class="mm-return"
        @click="back"
        style="position: absolute; left: 10px; top: 9px"
      >
        <svg
          t="1645171851221"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="81479"
          width="30"
          height="30"
        >
          <path
            d="M475 276V141.4c-12.1-56.3-58.2-22-58.2-22L96.6 395.9c-70.4 48.9-4.8 85.7-4.8 85.7l315.4 274.1c63.1 46.5 67.9-24.5 67.9-24.5V606.4C795.3 506 926.3 907.5 926.3 907.5c12.1 22 19.4 0 19.4 0C1069.4 305.4 475 276 475 276z"
            p-id="81480"
            fill="#5a5f69"
          ></path>
        </svg>
      </div>
      <div class="mm-search">
        <input
          type="text"
          id="content"
          :placeholder="placeholder"
          v-model="searchContent"
        />

        <span class="mm-close" v-show="close" @click="cancelContent()">
          <svg
            t="1645501617571"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            p-id="11225"
            width="16"
            height="16"
          >
            <path
              d="M512 128c211.2 0 384 172.8 384 384s-172.8 384-384 384-384-172.8-384-384 172.8-384 384-384m0-64C262.4 64 64 262.4 64 512s198.4 448 448 448 448-198.4 448-448-198.4-448-448-448z"
              fill="#ccc"
              p-id="11226"
            ></path>
            <path
              d="M646.4 332.8L512 467.2 377.6 332.8c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8L467.2 512l-134.4 134.4c-12.8 12.8-12.8 32 0 44.8s32 12.8 44.8 0L512 556.8l134.4 134.4c12.8 12.8 32 12.8 44.8 0s12.8-32 0-44.8L556.8 512l134.4-134.4c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0z"
              fill="#5a5f69"
              p-id="11227"
            ></path>
          </svg>
        </span>
      </div>

      <div class="mm-cancle" @click="search()">搜索</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonTop",
  display: "返回顶部",
  props: {
    title: {
      type: String,
      default: "",
    },
    needEmit: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入关键字",
    },
  },
  data() {
    return {
      close: false,
      searchContent: "",
    };
  },
  computed: {},
  components: {},
  methods: {
    // Clear the contents of the input box
    back() {
      if (this.needEmit) {
        this.$emit("searchBack");
      } else {
        this.$router.back(-1);
      }
    },
    cancelContent() {
      this.showHistory = true; // Show History Search Record
      this.initContent = true; // Show Contents of interestt
      var inputCon = document.getElementById("content");
      inputCon.value = "";
      this.searchContent = "";
      this.$emit("cancelContent");
    },
    search() {
      if (this.searchContent) {
        this.$emit("search", this.searchContent);
      }
    },
    keycodeFun(event) {
      if (event.keyCode == 13) {
        //如果按的是enter键 13是enter
        event.preventDefault(); //禁止默认事件（默认是换行）
        // console.log(event.target.value);
      }
      if (event.target.value) {
        this.searchContent = event.target.value;
      } else {
        return;
      }

      if (this.searchContent) {
        if (event.keyCode == 13) {
          //如果按的是enter键 13是enter
          event.preventDefault(); //禁止默认事件（默认是换行）
        }
        this.$emit("search", this.searchContent);
        this.showHistory = false; //  Hide History Search Record
        this.initContent = false; // Hide Contents of interest
      }
    },
  },
  watch: {
    searchContent: function (val) {
      if (val) {
        this.close = true;
      } else {
        this.close = false;
      }
    },
  },

  async created() {},
  beforeDestroy() {},
};
</script>
